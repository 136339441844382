export default{
    chooseMoneyType:'选择币种',
    rmbMoney:'人民币',
    euroMoney:'欧元',
    dollarMoney:'美元',
    chooseProduct:'选择产品',
    chooseTip:'请选择',
    changePrice:'修改产品价格',
    resetData:'重置',
    blockCost:'BLOCK成本',
    priceIncTax:'价格含税',
    thickness:'厚度',
    cutMethod:'加工方式',
    costUnitPrice:'成本单价',
    square:'平方',
    squareMetre:'平米',
    grossRate:'毛利率',
    saleUnitPrice:'销售单价',
    saleNum:'销售数量',
    cube:'立方',
    cubeMetre:'立方米',
    saleTotalMoney:'销售总金额',
    materialTCost:'原料总成本',
    processTCost:'加工总成本',
    addBtn:'添加',
    search:'查询',
    returnBack:'返回',
    choose:'选择',
    productName:'产品名称',
    processMethod:'加工方式',
    cost:'成本',
    saleMoney:'销售额',
    fuZhuPrice:'辅助报价',
    generatePTable:'生成报价单',
    tipMessage:'备注：请尝试点选多笔订单，合算功能可以辅助您报价,感谢使用',
    confirmCommit:'是否确认提交',
    confirm:'确认',
    yesBtn:'是',
    noBtn:'否',
    setPrice:'设置价格',
    DMYQianCao:'单面一字浅槽',
    SMYQianCao:'双面一字浅槽',
    DMSQianCao:'单面十字浅槽',
    SMSQianCao:'双面十字浅槽',
    DMYShenCao:'单面一字深槽',
    SMYShenCao:'双面一字深槽',
    lunKuoBan:'轮廓板',
    daKong:'打孔',
    DMBeiZhan:'单面背毡',
    SMBeiZhan:'双面背毡',
    moneyUnit:'元',
    tipParam:'请正确填写参数',
    tipSuccess:'修改成功',
    errorAjax:'请求失败，请联系管理员',
    loading:'加载中...',
    searching:'查询中...',
    noData:'没有数据',
    averageGRate:'平均毛利率',
    huiRate:'欧元汇率',
    transPrice:'运费+清关费',
    guanRate:'关税率 (%)',
    zengRate:'增值税率 (%)',
    transCarPrice:'运费 (元/车)',
    baoJiaDan:'产品报价单',
    prePage:'返回上一页',
    exportBtn:'导出',
    printBtn:'打印',
    fuZhu:'辅助计算',
    loginOut:'退出',
    priceCanKao:'价格参考',
    tipText:'提示',
    cancel:'取消',
    netWork:'网络异常导致计算失败，请删除正在输入的数值后重新输入！',


}