export default{
    chooseMoneyType:'Select Currency',
    rmbMoney:'RMB',
    euroMoney:'EUR',
    dollarMoney:'USD',
    chooseProduct:'Select Product',
    chooseTip:'Select',
    changePrice:'changePrice',
    resetData:'Reset Data',
    blockCost:'Block Cost',
    priceIncTax:'Price Inc. Tax',
    thickness:'Thickness',
    cutMethod:'Sheet With Finish',
    costUnitPrice:'Unit Price',
    square:'Square',
    squareMetre:'Square Meter',
    grossRate:'Gross Profit',
    saleUnitPrice:'Sales Unit Price',
    saleNum:'Sales Quantity',
    cube:'Cube',
    cubeMetre:'Cube Meter',
    saleTotalMoney:'Sales Total Amount',
    materialTCost:'Material Cost',
    processTCost:'Finishes Cost',
    addBtn:'Add',
    search:'Search',
    returnBack:'Back',

    choose:'Select',
    productName:'Product Name',
    processMethod:'Sheet With Finish',
    cost:'Cost',
    saleMoney:'Sales Amount',
    fuZhuPrice:'Quotation',
    generatePTable:'Generate Price List',
    tipMessage:'Tip: You can select different items at once, it will automatically import them in one quotation.',
    confirmCommit:'Do you want to submit',
    confirm:'Confirm',
    yesBtn:'Yes',
    noBtn:'No',
    setPrice:'Set Price',

    DMYQianCao:'Single Infusion Cut',
    SMYQianCao:'Double Infusion Cut',
    DMSQianCao:'Single Slit',
    SMSQianCao:'Double Slit',
    DMYShenCao:'Single Groove',
    SMYShenCao:'Double Groove',
    lunKuoBan:'Grid Score',
    daKong:'Perforate',
    DMBeiZhan:'Single Grid',
    SMBeiZhan:'Double Grid',


    moneyUnit:'Euro',
    tipParam:'Enter Correct Parameter',
    errorAjax:'Error Notice: Please contact manager user.',
    loading:'Loading...',
    searching:'Searching...',
    noData:'No Data',
    averageGRate:'Average Gross Profit Margin',

    huiRate:'rate',
    transPrice:'Freight',
    guanRate:'Import Tax (%)',
    zengRate:'VAT rate (%)',

    transPriceUnit:'Euro/Truck',
    baoJiaDan:'Quotation',
    prePage:'Previous Page',
    exportBtn:'Export',
    printBtn:'Print',
    fuZhu:'	Calculation Details',
    loginOut:'Log Out',
    priceCanKao:'Price Reference',
    tipText:'Tips',
    cancel:'Cancel',
    netWork:'Network exception causing calculation failure, please delete the value being entered and re-enter!'

}